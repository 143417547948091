import React from 'react';
import { CssBaseline, Typography, Button, Stack, Container, IconButton, Grid, Box } from '@mui/material';
import Image from './visionvend.svg';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function App() {

const currentYear = new Date().getFullYear();

return (
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
<CssBaseline />

<Container maxWidth="sm">
<Stack spacing={2} alignItems="center">

<a href="https://www.linkedin.com/company/yourvisionvend/" target="_blank" rel="noopener noreferrer">
<IconButton aria-label="LinkedIn">
<LinkedInIcon />
</IconButton>
</a>

<img src={Image} style={{ maxWidth: '100%', height: 'auto' }} />

<Typography variant="body1" align="center">
Dynamische Marketinglösungen, die fesseln und konvertieren.
</Typography>

<Box sx={{ flexGrow: 1 }}>
<Grid container spacing={2} justifyContent="center">

<Grid item>
<a href="mailto:diana@visionvend.ch" style={{ textDecoration: 'none' }}>
<Button variant="contained" color="primary">
diana@visionvend.ch
</Button>
</a>
</Grid>

<Grid item>
<a href="tel:+41445121420" style={{ textDecoration: 'none' }}>
<Button variant="contained" color="secondary">
044 512 14 20
</Button>
</a>
</Grid>

</Grid>
</Box>


<Typography variant="subtitle2" py={2} color="textSecondary">
© {currentYear} Visionvend. All rights reserved.
</Typography>

</Stack>

</Container>

</div>
);
}

export default App;